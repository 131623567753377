<template>
  <div class="page">
    <div class="line"></div>
    <div class="bgWhite" style=" display: flex;align-items: center;justify-content: center;font-size: 14px;line-height: 50px;color: red;">
      温馨提示 : 请认真核实发票信息，避免失误！
    </div>
    <div class="bgWhite">
      <cell-group>
        <Cell class="redVal" title="开票金额" :value="'￥' + invoiceMoney" />
        <Cell title="开票内容" :value="content" />
        <Cell title="发票类型" value="电子发票" />
      </cell-group>
    </div>
    <div class="line"></div>
    <!-- <div class="bgWhite"> -->
    <Tabs v-model="activeTab">
      <Tab title="企业单位"></Tab>
      <Tab title="个人/非企业"></Tab>
    </Tabs>

    <Form @submit="onSubmit" :show-error-message="false">
      <div class="contentBox">
        <div v-if="activeTab == 0">
          <Field
            v-model.trim="form.buyername"
            input-align="right"
            required
            :rules="[{ required: true, message: '请输入企业名称' }]"
            label="抬头"
            :maxlength="100"
            placeholder="企业名称"
          >
            <!-- <template #button>
              <Button plain size="small" @click="getWXTitle" color="#48AE5A">微信抬头</Button>
            </template> -->
          </Field>
          <Field
            v-model.trim="form.taxnum"
            input-align="right"
            required
            :rules="[{ required: true, message: '请输入税号' }]"
            label="税号"
            placeholder="请输入税号"
            :maxlength="20"
          />
          <div v-show="ifShowTextarea">
            <Field
              class="textAreaCell"
              v-model.trim="form.address"
              rows="2"
              autosize
              label="公司地址"
              type="textarea"
              placeholder="填请输入公司地址"
              :maxlength="50"
              show-word-limit
              label-class="textLabel"
            />
            <Field
              v-model="form.telephone"
              input-align="right"
              label="公司电话"
              placeholder="请输入公司电话"
            />

            <Field
              class="textAreaCell"
              v-model="form.bank"
              rows="3"
              autosize
              label="公司开户行"
              type="textarea"
              placeholder="请输入公司开户行"
              :maxlength="50"
              show-word-limit
              label-class="textLabel"
            />
            <Field
              v-model="form.account"
              input-align="right"
              label="开户行账号"
              placeholder="请输入开户行账号"
            />
            <Field
              class="textAreaCell"
              v-model="form.remark"
              rows="3"
              autosize
              label="备注（该内容会打印在发票）"
              type="textarea"
              placeholder="按企业报销填写要求"
              :maxlength="80"
              show-word-limit
              label-class="textLabel"
            />
          </div>
          <div
            class="more"
            v-if="!ifShowTextarea"
            @click="ifShowTextarea = true"
          >
            <span>更多内容</span>
            <Icon name="arrow-down" size="14" color="#2B72FF" />
          </div>
          <div class="more" v-else @click="ifShowTextarea = false">
            <span>收起</span>
            <Icon name="arrow-up" size="14" color="#2B72FF" />
          </div>
        </div>
        <div v-else>
          <Field
            v-model="form.buyername"
            input-align="right"
            required
            :rules="[{ required: true, message: '请输入抬头' }]"
            label="抬头"
            placeholder="个人/非企业"
          >
            <template #button>
              <Button plain size="small" color="#48AE5A">微信抬头</Button>
            </template>
          </Field>
          <Field
                  v-if="showCardId"
                  v-model="form.taxnum"
                  input-align="right"
                  required
                  :rules="[{ required: true, message: '请输入身份证号' }]"
                  label="身份证号"
                  placeholder="请输入身份证号"
                  :maxlength="20"
          />
        </div>
        <div class="line"></div>
        <Field
          v-model="form.email"
          input-align="right"
          required
          :rules="[{ required: true, message: '请输入电子邮件' }]"
          label="电子邮件"
          placeholder="请输入电子邮件"
        />

        <Field
          v-if="content!='会费'"
          v-model="form.phone"
          type="tel"
          input-align="right"
          label="手机号码"
          placeholder="请输入手机号码"
        />
        <div class="line"></div>
      </div>
      <div class="btnBox">
        <Button
          class="border-radius-5"
          color="#2b72ff"
          block
          native-type="submit"
          >提交开票</Button
        >
      </div>
    </Form>
    <Popup v-model="showModal" round position="bottom">
      <div class="popBox">
        <div class="popTitle">
          <span>确认开票</span>
          <Icon
            name="cross"
            color="#000"
            size="19"
            @click="showModal = false"
          />
        </div>
        <div class="infoList">
          <Cell>
            <template #title>
              <span class="cellTitle">发票抬头：</span>
              <span class="cellInfo">{{ form.buyername }}</span>
            </template>
          </Cell>
          <Cell v-if="activeTab == 0">
            <template #title>
              <span class="cellTitle">税号：</span>
              <span class="cellInfo">{{ form.taxnum }}</span>
            </template>
          </Cell>
          <Cell>
            <template #title>
              <span class="cellTitle">开票金额：</span>
              <span class="cellInfo red">￥{{ invoiceMoney }}</span>
            </template>
          </Cell>
          <Cell>
            <template #title>
              <span class="cellTitle">电子邮箱：</span>
              <span class="cellInfo">{{ form.email }}</span>
            </template>
          </Cell>
          <Cell
                  v-if="content!='会费'"
          >
            <template #title>
              <span class="cellTitle">手机号码：</span>
              <span class="cellInfo">{{ form.phone || "-" }}</span>
            </template>
          </Cell>
        </div>
        <div class="mar-12">
          <Button block class="border-radius-5" :loading='loading'  loading-text="加载中..." color="#2b72ff" @click="submit"
            >确认提交</Button
          >
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import { Cell, CellGroup, Tabs, Tab, Form,Field, Button, Icon,Popup, Notify,Toast ,Dialog } from "vant";
import {getGoodInfo,applyInvoice} from "@/api/my.js";

export default {
  name: "invoice",
  components: {
    Cell,
    CellGroup,
    Tabs,
    Tab,
    Form,
    Field,
    Button,
    Icon,
    Popup,
    [Notify.Component.name]: Notify.Component,
    Toast
  },
  data() {
    return {
      orderId:'',
      //商品信息
      goodInfo:{},
      showCardId:false,
      money: "",
      invoiceMoney:'',
      type:'',
      content:'培训费',
      activeTab: 0,
      ifShowTextarea: false,
      form: {
        bank:'',
        account: "",
        address: "",
        telephone:'',
        buyername:'',
        remark: "",
        email: "",
        phone: "",
        taxnum:'',
      },
      showModal:false,
      loading:false,
    };
  },
  // mounted(){
  //    let mchData={action:"jumpOut",jumpOutUrl:config.baseUrl.onlineUrl+`/invoice?type=${this.type}&countNum=${this.countNum}&goodId=${this.goodsId}&orderId=${this.orderId}&money=${this.money}`};
  //     let postData=JSON.stringify(mchData)
  //     parent.postMessage(postData, 'https://payapp.weixin.qq.com')
  // },
  created() {
    // this.ssd()
    //this.getWxConfig();
    if(this.$route.query&&this.$route.query.noCardId){
      this.showCardId=false
    }else{
      this.showCardId=true
    }
    this.goodsId=this.$route.query.goodsId;//培训0000，会员1111
    this.orderId=this.$route.query.orderId;//订单id
    this.money=this.$route.query.money;
    this.invoiceMoney=(this.$route.query.goodsId=='0000')?this.$route.query.invoiceMoney:this.$route.query.money
    //缴费人数，培训可能多个，会员就是1
    this.countNum=this.$route.query.count||1;
    this.getOrderInfo(this.$route.query.goodsId);
    let prop=this.$route.query.type
    this.type=prop;
    if(prop && prop=='member'){
      this.content='会费'
    }
    //获取缓存中失败的数据
    let invoiceInfo=sessionStorage.getItem('invoiceInfo')||"{}";

    invoiceInfo=JSON.parse(invoiceInfo);
    if(this.orderId==invoiceInfo.billId){

      this.activeTab=invoiceInfo.activeTab||0
      let{
          bank,
        account,
        address,
        telephone,
        buyername,
        remark,
        email,
        phone,
        taxnum,
        }=invoiceInfo;
        this.form={
          bank,
        account,
        address,
        telephone,
        buyername,
        remark,
        email,
        phone,
        taxnum,
        }
    }
  },
  methods: {
    ssd(){
      Dialog.alert({
        title: '温馨提示',
        message: '请认真核实发票信息，避免失误！',
      }).then(() => {
        // on close
      });
    },
    getWxConfig() {
      //请求后台的接口获取返回值后配置
      wx.config({
        //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "wxcedde571c64f953f", // 必填，公众号的唯一标识
        timestamp: "", // 必填，生成签名的时间戳
        nonceStr: "", // 必填，生成签名的随机串
        signature: "", // 必填，签名
        jsApiList: ["chooseInvoiceTitle"], // 必填，需要使用的JS接口列表
        beta: true, //接口没有公开,wx.config 的时候传入参数 beta: true
      });
    },
    getWXTitle() {
      wx.invoke(
        "chooseInvoiceTitle",
        {
          // 这里要传入参数
          scene:"1"
        },
        function(res) {
          var info = JSON.parse(JSON.stringify(res)).choose_invoice_title_info;
          info = JSON.parse(JSON.parse(JSON.stringify(info)));

          console.log(info);
          console.log(info.title);
          console.log(info.taxNumber);
          console.log(info.companyAddress);
          console.log(info.telephone);
          console.log(info.bankName);
          console.log(info.bankAccount);
        }
      );
    },
    //获取订单信息
    getOrderInfo(goodsId){
      getGoodInfo({goodsId}).then(res=>{
        if(res.status=="S"){
          this.goodInfo=res.data;
        }
      })
    },
    onSubmit(){
  // \\w+(\\.\\w)*@\\w+(\\.\\w{2,3}){1,3}
      var pattern = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
      ///\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      var phoneReg=/^(13|14|15|17|18|19|16)[0-9]{9}$/;

      if(!pattern.test(this.form.email)){
        Notify({ type: 'warning', message: '请输入正确格式的电子邮箱！' });
        return false;
      }
      if(this.form.phone&&!phoneReg.test(this.form.phone)){
        Notify({ type: 'warning', message: '请输入正确格式的手机号码！' });
        return false;
      }
      if(this.activeTab==0){
        let { address,telephone,bank,account}=this.form;
        address=address?address.length:0;
        telephone=telephone?telephone.length:0;
        bank=bank?bank.length:0;
        account=account?account.length:0;
          if((address+ telephone)>50){
            Toast('购方地址电话长度不能超过50个字！');
            return false;
          }
          if((bank+account)>50){
            Toast('购方开户行及账号不能超过50个字！');
            return false;
          }
      }
      this.showModal=true
    },
    submit(){
        this.loading=true
        let {
          bank,
        account,
        address,
        telephone,
        buyername,
        remark,
        email,
        phone,
        taxnum,
        }=this.form;
        let {
          goodsName,
          price,
          taxFlag,
          taxRate,
          taxTypeCode,
        }=this.goodInfo
        let userId=localStorage.getItem("clientId"),
        billId=this.orderId,
        countNum=this.countNum;
        let params={}
        if(this.activeTab==0){

         params={
          bank,
        account,
        address,
        telephone,
        buyername,
        remark,
        email,
        phone,
        taxnum,
         goodsName,
          price:price|| (this.money/countNum),
          taxFlag,
          taxRate,
          taxTypeCode,
          userId,
          billId,
          countNum,
           custType:'2',
          billType:this.goodsId=='0000'?'0':'1'
        }
        }else{
          params={
          bank:'',
        account:'',
        address:'',
        telephone:'',
        buyername,
        remark:'',
        email,
        phone,
        taxnum:taxnum||'',
         goodsName,
          price:price|| (this.money/countNum),
          taxFlag,
          taxRate,
          taxTypeCode,
          userId,
          billId,
          countNum,
            custType:'1',
           billType:this.goodsId=='0000'?'0':'1'
        }
        }

        applyInvoice(params).then(res=>{
          if(res.status=="S"){
            this.$router.push('/invoiceSuccess')
          }
          else{
            this.$router.push('/invoiceFailed?status='+res.status)
            params.activeTab=this.activeTab;
            let infos=JSON.stringify(params)
            sessionStorage.setItem('invoiceInfo',infos)
          }
        })

    }
  },
  beforeDestroy(){
    this.loading=false;
  }
};
</script>

<style lang="less" scoped>
::v-deep.van-cell {
  flex-wrap: wrap;
  padding: 16px;
  .van-field__label {
    font-size: 14px;
    color: #333;
  }
  .textLabel {
    display: block;
    width: 100vw !important;
  }
  &.textAreaCell {
    .van-field__word-limit {
      position: absolute;
      top: -25px;
      right: 0;
      margin-top: 4px;
      color: #333;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
    }
    .van-field__word-num {
      color: #999;
    }
    .van-field__body {
      margin-top: 5px;
    }
  }
  .van-field__button {
    display: flex;
    align-items: center;
    .van-button--small {
      height: 24px;
    }
  }
}
.redVal ::v-deep.van-cell__value {
  color: #f62f2f;
  font-size: 14px;
}
::v-deep.van-tabs {
  .van-tabs__line {
    width: 50vw;
    background-color: #2b72ff;
    height: 2px;
  }
  .van-tab {
    .van-tab__text {
      color: #999;
    }
  }
  .van-tab--active {
    .van-tab__text {
      color: #333;
    }
  }
}
.page {
  background: #f2f2f2;
  min-height: 100vh;
}
.line {
  width: 100vw;
  height: 0.1rem;
  background: #f2f2f2;
}
.bgWhite {
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
}
.more {
  height: 0.4rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #2b72ff;
  font-size: 14px;
  background: #fff;
  span {
    margin-right: 5px;
  }
}
.contentBox {
  max-height: calc(~"100vh - 3.04rem");
  overflow-y: auto;
  box-sizing: border-box;
}
.btnBox {
  height: 0.72rem;
  width: 100%;
  background: #fff;
  padding: 0.15rem 0.12rem 0;
  box-sizing: border-box;
}
.border-radius-5 {
  border-radius: 5px;
  height: 0.42rem;
}
.popBox {
  .popTitle {
    font-size: 18px;
    color: #333;
    height: 0.54rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.25rem;
    border-bottom: 1px solid #eee;
    font-weight: bold;
  }
  .infoList {
    margin: 0 0.12rem;
    border-bottom: 1px solid #eee;
  }
  ::v-deep.van-cell {
    padding: 16px 10px;
    &::after {
      border-bottom: 0px solid #eee;
    }
    .cellTitle {
      color: #999;
      display: inline-block;
      width: 70px;
    }
    .cellInfo {
      color: #333;
    }
    .red {
      color: #f62f2f;
    }
  }
  .mar-12 {
    padding: 0.25rem 0.12rem;
  }
}
</style>
